
import * as _ from "lodash";
import Swal from 'sweetalert2'
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import StudyCreationBase from '@/components/StudyCreation/StudyCreationBase.vue'
import Collapsable from '~/components/Collapsable/Collapsable.vue'
import ProgressModal from '@/components/StudyCreation/ProgressModal.vue'
import RangeSlider from '~/components/RangeSlider/RangeSlider.vue'
import WatFocusCalendar from '@/components/StudyCreation/WatFocusCalendar.vue'
import store from '@/store'
import { ProductTypes, userOrigin } from '@/utils/constants.ts'

export default defineComponent({
  name : 'Step4WatFocus',
  components:{
    StudyCreationBase,
    ProgressModal,
    Collapsable,
    RangeSlider,
    WatFocusCalendar
  },
  computed: {
    ...mapGetters([
      'watFocusSteps',
    ])
  },
  props:{
    STEP:[String, Number]
  },
  data(){
    return{
      collapse: false,
      errorMessage:this.$t('suite_survey_step3_error'),
      error: false,
      showProgress: false,
      sessionMinutes:30,
      ranges:[],
      sessionNum: 0,
      isLoaded: false
    }
  },
  methods:{
    beforeSave(){
      this.error = false
      const calendar: any = this.$refs.Calendar
      const config = {
        sessionMinutes : this.sessionMinutes,
        ranges: calendar.getRangesFormated()
      }
      if(config.ranges.length == 0) return
      this.showProgress = true
      store.dispatch('saveWatFocus', {contractId:this.$route.params.id, data:config})
      .then((data) => {
        this.showProgress = false
        this.$router.push({name: 'newstudy.step5WatFocus', params:{id: this.$route.params.id}})
      })
      .catch((response)  => {
        console.log(response)
        if(response.payload == 'slot_num_error'){
          this.error = true
          this.errorMessage = this.$t('suite_watfocus_step4_error_slotsnum',{number:this.sessionNum})
          this.showProgress = false
          return 
        }
        this.error = true
        this.errorMessage = this.$t('suite_study_error_save')
        this.showProgress = false
      })
    },
    fetchWatFocusConfig(){
      store.dispatch('fetchWatFocusConfiguration',{contractId:this.$route.params.id}).then((data) => {
        this.ranges = data.ranges
        this.sessionMinutes = data.sessionMinutes
        this.sessionNum = data.sessionNum
        this.isLoaded = true
      })
    }
  },
  mounted(){
    $("html, body").animate({ scrollTop: 0 }, 400);
    this.fetchWatFocusConfig()
  }
})
